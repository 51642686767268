import Phaser from 'phaser'
import ControlsGameObject from "../../objects/ControlsGameObject";
import EventDispatcher from "../../objects/EventDispatcher";
import ShotCalculator, {ShotParameters} from "@/game/objects/ShotCalculator";
import Vector2 = Phaser.Math.Vector2;
import Polygon = Phaser.GameObjects.Polygon;
import GameStatistics from "@/game/objects/GameStatistics";
import {GameStatus, Lie} from "@/game/objects/GameStatus";
import {Club} from "@/game/objects/Clubs";
import Help from "@/game/objects/Help";

export default class RangeScene extends Phaser.Scene {

    private shotButton: any;
    private config: any;
    private emitter: any;
    private readonly shots: Set<ShotParameters>;

    private currentShot: any;

    private readonly statistics: GameStatistics;
    private readonly status: GameStatus;

    private teeCoordinates: any;

    private clubText: any;

    private sceneCamera: any;
    private uiCamera: any;
    private bounds = {width: 0, height: 0};

    private sceneObjects: Set<any> = new Set<any>();
    private uiObjects: Set<any> = new Set<any>();
    
    private help: Help = new Help();

    constructor() {
        super('hole-scene')
        this.shots = new Set<ShotParameters>();
        this.statistics = new GameStatistics();
        this.status = new GameStatus();
    }

    init(config: any) {
        this.config = config;
    }

    preload() {
        this.emitter = EventDispatcher.getInstance();
    }

    openExternalLink () {
        window.location.href = '/';
    }
    
    create() {
        this.emitter.on('shot-complete', this.handleShot.bind(this));
        this.emitter.on('item-added', this.handleItemAddedToScene.bind(this));
        this.emitter.on('ui-item-added', this.handleItemAddedToUi.bind(this));

        const width = this.game.canvas.width
        const height = this.game.canvas.height;

        this.bounds.width = width;
        this.bounds.height = height;

        this.sceneCamera = this.cameras.main;

        this.uiCamera = this.cameras.add(this.sceneCamera.x, this.sceneCamera.y, this.sceneCamera.width, this.sceneCamera.height);

        this.sceneCamera.setOrigin(0.5, 0.5);
        this.sceneCamera.setBounds(0, 0, this.bounds.width, this.bounds.height);

        this.sceneCamera.x = 20;

        this.uiObjects.add(this.add.rectangle(0, 0, width, 40, 0x335566).setOrigin(0, 0).setDepth(20));

        this.uiObjects.add(this.add.text(10, 10, 'ALLMYGOLF')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(16)
            .setOrigin(0, 0)
            .setDepth(20)
            .setInteractive({ useHandCursor: true  })
            .on('pointerdown', this.openExternalLink, this));

        this.uiObjects.add(this.add.circle(width - 60, 5, 15)
            .setDepth(21)
            .setStrokeStyle(1, 0xffffff, 1)
            .setOrigin(0, 0)
            .setInteractive({ useHandCursor: true  })
            .on('pointerdown', this.showHelp, this));

        this.uiObjects.add(this.add.text(width - 51, 8, '?')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(22)
            .setOrigin(0, 0)
            .setDepth(20));

        this.uiObjects.add(this.add.rectangle(40, height - 130, 60, 70, 0x000000, 1)
            .setOrigin(0, 0)
            .setStrokeStyle(2, 0x000000, 1)
            .setDepth(100));

        this.uiObjects.add(this.add.text(45, height - 88, 'CLUB')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(18)
            .setDepth(101)
            .setOrigin(0, 0));

        this.clubText = this.add.text(45, height - 123, 'CLUB')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(24)
            .setDepth(101)
            .setOrigin(0, 0);

        this.uiObjects.add(this.clubText);

        this.uiObjects.add(this.add.rectangle(110, height - 130, 30, 30, 0x000000, 1)
            .setOrigin(0, 0)
            .setStrokeStyle(2, 0x000000, 1)
            .setDepth(100)
            .setInteractive({ useHandCursor: true  })
            .on('pointerdown', () => {
                this.status.Club = this.status.Clubs.GetNextClub(this.status.Club);
                this.clubText.setText(this.status.Club.Name);
                this.currentShot.destroy();
                this.currentShot = new ControlsGameObject(this, this.teeCoordinates.x, this.teeCoordinates.y, 0, this.status.Club, this.status.Lie);
            }));

        this.uiObjects.add(this.add.text(118, height - 130, '+')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(26)
            .setDepth(101)
            .setOrigin(0, 0));

        this.uiObjects.add(this.add.rectangle(110, height - 90, 30, 30, 0x000000, 1)
            .setOrigin(0, 0)
            .setStrokeStyle(2, 0x000000, 1)
            .setDepth(100)
            .setInteractive({ useHandCursor: true  })
            .on('pointerdown', () => {
                this.status.Club = this.status.Clubs.GetPreviousClub(this.status.Club);
                this.clubText.setText(this.status.Club.Name);
                this.currentShot.destroy();
                this.currentShot = new ControlsGameObject(this, this.teeCoordinates.x, this.teeCoordinates.y, 0, this.status.Club, this.status.Lie);
            }));

        this.uiObjects.add(this.add.text(120, height - 92, '-')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(26)
            .setDepth(101)
            .setOrigin(0, 0));

        this.renderTee();

        this.uiObjects.add(this.shotButton = this.add.circle(width - 100, height - 130, 30, 0x7199aa, 1)
            .setOrigin(0, 0)
            .setStrokeStyle(2, 0x000000, 1)
            .setDepth(100)
            .setInteractive({ useHandCursor: true  })
            .on('pointerdown', this.shotButtonClicked));
    }

    showHelp() {
        this.help.show(this);
    }

    handleShot(shotParameters: ShotParameters) {
        const isValidShot = this.isValidShot(shotParameters);

        if (isValidShot) {
            this.shots.add(shotParameters);
            this.statistics.Score++;
        } else {
            this.statistics.Score++;
            this.currentShot.destroy();
            this.currentShot = new ControlsGameObject(this, shotParameters.start.x, shotParameters.start.y, 0, this.status.Club as Club, this.status.Lie);
        }
    }

    shotButtonClicked() {
        EventDispatcher.getInstance().emit('shot-button-clicked', {});
    }

    update() {
        this.shots.forEach((e) => {
            if (e.Points.length == 0) {
                e.Points = this.calculatePoints(e);
                e.RenderedQueue = Array.from(e.Points);
            } else {
                if (e.RenderedQueue.length > 0) {

                    const radius = 2;

                    const ball = this.add.circle(
                        e.RenderedQueue[0].x,
                        e.RenderedQueue[0].y,
                        radius,
                        0xffffff, 1)
                        .setDepth(44);

                    this.sceneObjects.add(ball);

                    this.time.delayedCall(1000, () => {
                        ball.destroy();
                    }, [], this);

                    e.RenderedQueue.splice(0, 1);

                    if (e.RenderedQueue.length == 0) {

                        const lastPoint = e.Points[e.Points.length - 1];

                        this.sceneObjects.add(this.add.circle(
                            lastPoint.x,
                            lastPoint.y,
                            3,
                            0xffffff, 1)
                            .setDepth(44));

                        const distance = Phaser.Math.Distance.BetweenPoints(
                            new Vector2(this.teeCoordinates.x, this.teeCoordinates.y),
                            lastPoint);

                        this.showMessage(this.status.ModifyYardage(distance) + " yds");

                        this.currentShot.destroy();

                        this.currentShot = new ControlsGameObject(this, this.teeCoordinates.x, this.teeCoordinates.y, 0,
                            this.status.Club as Club, this.status.Lie);
                    }
                }
            }
        });

        this.sceneCamera.ignore(Array.from(this.uiObjects.values()));
        this.uiCamera.ignore(Array.from(this.sceneObjects.values()));
    }

    showMessage(message: string, interval: integer = 3000) {

        const messageText = this.add.text(this.sys.game.scale.gameSize.width / 2, 100, message)
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(20)
            .setDepth(100)
            .setOrigin(0.5, 1);

        const messageBox = this.add.rectangle(
            this.sys.game.scale.gameSize.width / 2, messageText.y - 10,
            messageText.width + 30,
            messageText.height + 30,
            0x000000, 1)
            .setDepth(99)
            .setOrigin(0.5, 0.5);

        this.uiObjects.add(messageText);
        this.uiObjects.add(messageBox);

        this.time.delayedCall(interval, () => {
            messageText.destroy();
            messageBox.destroy();
        }, [], this);
    }

    isValidShot(shotParameters: ShotParameters): boolean {

        //const {width, height} = this.sys.game.scale.gameSize;

        const {width, height} = this.bounds;
        const points = this.calculatePoints(shotParameters);
        let valid = true;
        points.forEach((p) => {
            if (valid) {
                if (p.x < 0) {
                    valid = false;
                    this.showMessage("Out of bounds");
                }
                if (p.y < 0) {
                    valid = false;
                    this.showMessage("Out of bounds");
                }
                if (p.x > width) {
                    valid = false;
                    this.showMessage("Out of bounds");
                }
                if (p.y > height) {
                    valid = false;
                    this.showMessage("Out of bounds");
                }
            }
        });

        return valid;
    }

    calculatePoints(e: ShotParameters): Vector2[] {

        const parameters = ShotCalculator.Calculate(e);

        const curve = new Phaser.Curves.QuadraticBezier(
            parameters.start,
            <Vector2>parameters.middle,
            <Vector2>parameters.end);

        const points = curve.getPoints(parameters.Power * 7) as Vector2[];

        const finishedPoints = new Set<Vector2>();

        points.forEach(m => {
            finishedPoints.add(m);
        });

        return Array.from(finishedPoints);
    }

    renderItem(item: any) {
        item.points.forEach((p: any) => {

            const curve = new Phaser.Curves.Spline(p.concat(p));

            const graphics = this.add.graphics()
                .lineStyle(item.style.stroke.width, item.style.stroke.colour, item.style.stroke.alpha)
                .setDepth(item.depth)
                .fillStyle(item.style.fill.colour, item.style.fill.alpha)

            curve.draw(graphics, 200);
            graphics.fillPath();
        });
    }

    isIn(item: any, point: Vector2): boolean {
        let isIn = false;
        item.points.forEach((p: any) => {
            const poly = new Polygon(this, 0, 0, p).setOrigin(0, 0);
            if (Phaser.Geom.Polygon.ContainsPoint(poly.geom, new Phaser.Geom.Point(point.x, point.y))) {
                isIn = true;
            }
        });
        return isIn;
    }

    renderTee() {
        const {width, height} = this.bounds;

        this.teeCoordinates = {x: width / 2, y: height - 30};
        const angle = 0;

        this.status.Club = this.status.Clubs.GetDiver();
        this.status.Lie = Lie.Tee;

        for (let i = 0; i < 6; i++) {
            const offset = 110 * i;
            const yardage = 50 * i;

            this.sceneObjects.add(this.add.line(0, 0, 0, this.teeCoordinates.y - offset, width, this.teeCoordinates.y - offset)
                .setOrigin(0, 0)
                .setStrokeStyle(1, 0xffffff, 1)
                .setDepth(30));

            this.sceneObjects.add(this.add.text(30, this.teeCoordinates.y - 20 - offset, yardage + ' yards')
                .setDepth(31)
                .setOrigin(0, 0));
        }

        this.clubText.setText(this.status.Club.Name);
        this.currentShot = new ControlsGameObject(this, this.teeCoordinates.x, this.teeCoordinates.y, angle, this.status.Club, this.status.Lie);

        this.sceneObjects.add(this.currentShot);
        this.sceneCamera.zoom = 0.9;
        this.sceneCamera.setOrigin(0.5, 0.5);
    }

    handleItemAddedToScene(item: any) {
        this.sceneObjects.add(item);
    }

    handleItemAddedToUi(item: any){
        this.uiObjects.add(item);
    }
}
