import Phaser from 'phaser'
import config from '@/game/assets/range.json'
import face from '@/game/assets/face.png'
import path from '@/game/assets/path.png'
import target from '@/game/assets/target.png'
import controls from '@/game/assets/controls.png'
import instructions from '@/game/assets/instructions.svg'

export default class BaseScene extends Phaser.Scene {
    constructor() {
        super('base-scene')
    }

    preload() {
        this.load.json('config', config);
        this.load.image('face', face);
        this.load.image('path', path);
        this.load.image('target', target);
        this.load.spritesheet("controls", controls, { frameWidth: 50, frameHeight: 50 });
        this.load.svg('instructions', instructions);
    }
    
    create() {
        this.scene.start('hole-scene', this.cache.json.get('config'));
    }
}
