export default class GameStatistics {

    public GreenInRegulation: boolean;
    public FairwayInRegulation: boolean;
    public Putts: integer;
    public LongestDistance: integer;
    public DriveDistance: integer;
    public LastPlayed: number;
    public Score: integer;
    public TotalHoleDistance: integer;

    constructor() {
        this.Score = 0;
        this.GreenInRegulation = false;
        this.FairwayInRegulation = false;
        this.Putts = 0;
        this.LongestDistance = 0;
        this.DriveDistance = 0;
        this.LastPlayed = 0;
        this.TotalHoleDistance = 0;
    }

    public IsToday(): boolean {
        const today = new Date(Date.now());
        const lastPlayed = new Date(this.LastPlayed);
        return lastPlayed.getDate() == today.getDate() &&
            lastPlayed.getMonth() == today.getMonth() &&
            lastPlayed.getFullYear() == today.getFullYear()
    }
    
    public GetNextGameTime() : string{
        const now = new Date() as any;
        const tomorrow = new Date() as any;
        tomorrow.setDate(tomorrow.getDate() + 1)
        tomorrow.setHours(0,0,0,0);

        const diff = tomorrow - now;

        const hours   = Math.floor(diff / 3.6e6);
        const minutes = Math.floor((diff % 3.6e6) / 6e4);
        const seconds = Math.floor((diff % 6e4) / 1000);
        
        return this.pad(hours, 2) + ':' + this.pad(minutes, 2) + ':' + this.pad(seconds, 2);
    }

    pad(num:number, size:number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }
}