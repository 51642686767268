<template>
  <div class="h-100 fairway" v-if="!isMobile()">
    <div class="h-100 container ">
      <div class="h-100 row">
        <div class="h-100 col-2">&nbsp;</div>
        <div class="h-100 col-8 parent-container">
          <div class="" :id="containerId" v-if="downloaded"/>
          <div class="" v-else>
            Downloading ...
          </div>
        </div>
        <div class="h-100 col-2">&nbsp;</div>
      </div>
    </div>
  </div>
  <div class="h-100 fairway" v-if="isMobile()">
    <div :id="containerId" v-if="downloaded"/>
    <div class="" v-else>
      Downloading ...
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      downloaded: false,
      gameInstance: null,
      containerId: 'game-container'
    }
  },
  methods: {
    isMobile() {
      return screen.width <= 760;
    }
  },
  async mounted() {
    const game = await import(/* webpackChunkName: "game" */ '@/game/range')
    this.downloaded = true;
    await this.$nextTick(() => {
      this.gameInstance = game.launch(this.containerId);
    })
  },
  async unmounted() {
    this.gameInstance.destroy(false);
  }
}
</script>

<style lang="scss" scoped>
.fairway {
  background-color: #667f29;
}
</style>