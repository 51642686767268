import Phaser from 'phaser'
import face from '@/game/assets/face.png'
import path from '@/game/assets/path.png'
import target from '@/game/assets/target.png'
import controls from '@/game/assets/controls.png'
import tree1 from '@/game/assets/tree1.svg'
import tree2 from '@/game/assets/tree2.svg'
import instructions from '@/game/assets/instructions.svg'
import {getDailyConfig} from "@/game/objects/Api";
import {getStyle} from "@/game/objects/Api";

export default class BaseScene extends Phaser.Scene {
    constructor() {
        super('base-scene')
    }

    preload() {

        const width = this.cameras.main.width;
        const height = this.cameras.main.height;
        
        const progressBar = this.add.graphics();
        const progressBox = this.add.graphics();
        progressBox.fillStyle(0x222222, 0.8);
        progressBox.fillRect(width/2 - 100, height / 2 - 30, width / 2, 50);
        
        const loadingText = this.make.text({
            x: width / 2,
            y: height / 2 - 50,
            text: 'Loading...',
            style: {
                font: '20px monospace',
                color: '#ffffff'
            }
        });
        loadingText.setOrigin(0.5, 0.5);

        const percentText = this.make.text({
            x: width / 2,
            y: height / 2 - 5,
            text: '0%',
            style: {
                font: '18px monospace',
                color: '#ffffff'
            }
        });
        percentText.setOrigin(0.5, 0.5);
        
        this.load.on('progress', function (value:number) {
            const percentage = Phaser.Math.RoundTo(value, 0) * 100 + '%';
             percentText.setText(percentage);
             progressBar.clear();
             progressBar.fillStyle(0xffffff, 1);
             progressBar.fillRect(width/2 - 100, height / 2 - 20, width / 2 * value, 30);
        });

        this.load.on('complete', function () {
            progressBar.destroy();
            progressBox.destroy();
            loadingText.destroy();
            percentText.destroy();
        });
        
        this.load.image('face', face);
        this.load.image('path', path);
        this.load.image('target', target);
        this.load.spritesheet("controls", controls, { frameWidth: 50, frameHeight: 50 });
        this.load.svg('tree1', tree1);
        this.load.svg('tree2', tree2);
        this.load.svg('instructions', instructions);
    }
    
    async create() {
        this.scene.start('hole-scene', {style:await getStyle(), config:await getDailyConfig()});
    }
}
