import Vector2 = Phaser.Math.Vector2;
import Phaser from "phaser";
import {Lie} from "@/game/objects/GameStatus";
import {Club} from "@/game/objects/Clubs";

export default class ShotCalculator {

    static percentage(num: integer, amount: integer) {
        return num * (amount / 100);
    }

    public static Calculate(parameters: ShotParameters): ShotParameters {
        let power = parameters.Power * parameters.Club.Power;

        switch (parameters.Lie) {
            case Lie.Rough:
                if (parameters.IsFlyer) {
                    power += this.percentage(power, parameters.PowerReductionForBadLie);
                } else {
                    power -= this.percentage(power, parameters.PowerReductionForBadLie);
                }
                break;
            case Lie.Bunker:
                power -= this.percentage(power, parameters.PowerReductionForBadLie + 4);
                break;
            case Lie.Ground:
            case Lie.Trees:
                power -= this.percentage(power, parameters.PowerReductionForBadLie);
                break;
        }

        if (parameters.PowerRandomise == 1) {
            power += this.percentage(power, parameters.PowerVariation);
        } else if (parameters.PowerRandomise == 2) {
            power -= this.percentage(power, parameters.PowerVariation);
        }

        const targetAngle = Math.round(parameters.Target);
        const faceAngle = Math.round(parameters.Face);
        const pathAngle = Math.round(parameters.Path);

        const calculatedFaceAngle = faceAngle - targetAngle;
        const calculatedPathAngle = pathAngle - targetAngle;

        const faceToPath = calculatedFaceAngle - calculatedPathAngle;
        const curvature = Math.round(faceToPath * (parameters.Club.CurveModifier * (parameters.Power / 9)));

        const radians = Phaser.Math.DegToRad(-faceAngle);

        let endX = parameters.start.x - (Math.sin(radians) * power)
        const endY = parameters.start.y - (Math.cos(radians) * power);

        const middleX = parameters.start.x - (Math.sin(radians) * (power / 2));
        const middleY = parameters.start.y - (Math.cos(radians) * (power / 2));

        endX += curvature;

        parameters.end = new Phaser.Math.Vector2(endX, endY);
        parameters.middle = new Phaser.Math.Vector2(middleX, middleY);

        return parameters;
    }
}

export class ShotParameters {
    public start: Vector2;
    public end: Vector2 | null = null;
    public middle: Vector2 | null = null;
    public Target: integer;
    public Face: integer;
    public Path: integer;
    public Power: integer;
    public Points: Vector2[] = [];
    public RenderedQueue: Vector2[] = [];
    public Club: Club;
    public Lie: Lie;
    public IsFlyer: boolean;
    public PowerReductionForBadLie: integer;
    public PowerVariation: integer;
    public PowerRandomise: integer;

    constructor(
        start: Vector2,
        target: integer,
        face: integer,
        path: integer,
        power: integer,
        club: Club,
        lie: Lie) {
        this.start = start;
        this.Target = target;
        this.Face = face;
        this.Path = path;
        this.Power = power;
        this.Club = club;
        this.Lie = lie;
        this.IsFlyer = Phaser.Math.Between(1, 3) == 1;
        this.PowerReductionForBadLie = Phaser.Math.Between(5, 15);
        this.PowerRandomise = Phaser.Math.Between(1, 3);
        this.PowerVariation = Phaser.Math.Between(1, 8);
    }
}

