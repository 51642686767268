
import {Options, Vue} from 'vue-class-component';
import Game from '@/components/Game.vue'

@Options({
  components: {
    Game
  },
})
export default class GameRoute extends Vue {
}
