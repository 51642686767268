import axios from 'axios'

const http = axios.create({
    baseURL: GetBaseApiUrl(),
    headers: { 'Content-Type': 'application/json' }
})

function GetBaseApiUrl(): string{
    let apiUri = `${process.env.VUE_APP_API_BASE_URL}/api`;
    if(window.location.href.includes('www')){
        apiUri = apiUri.replace('https://', 'https://www.');
    }
    return apiUri;
}

export async function getDailyConfig (): Promise<any> {
    const response = await http.get(`/getholeconfig`)
    return response.data
}

export async function getStyle (): Promise<any> {
    const response = await http.get(`/getstyle`)
    return response.data
}