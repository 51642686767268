import EventDispatcher from "@/game/objects/EventDispatcher";

export default class Help {

    private container: any;
    private isHelpShowing = false;
    private emitter: EventDispatcher;
    
    constructor() {
        this.emitter = EventDispatcher.getInstance();
    }

    show(scene: Phaser.Scene) {

        if (this.isHelpShowing) {
            return;
        }

        this.isHelpShowing = true;

        const width = scene.game.canvas.width
        const height = scene.game.canvas.height;

        const background = scene.add.rectangle(width / 2, 42, width - 40, height - 140, 0xffffff).setOrigin(0.5, 0).setDepth(700);

        const {x, y} = background.getTopRight();

        const close = scene.add.text(x - 30, y + 30, 'x')
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(20)
            .setOrigin(0.5, 1)
            .setInteractive({ useHandCursor: true  })
            .on('pointerdown', () => {
                this.container.destroy();
                this.isHelpShowing = false;
            }, this);

        const howToPlay = scene.add.text(width / 2, y + 30, 'HOW TO PLAY')
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setOrigin(0.5, 1);

        const text1 = scene.add.text(40, howToPlay.getBottomCenter().y + 10, 'On the course the goal is to get the ball in the hole in the least amount of shots. On the range it\'s up to you – longest drive, shot accuracy or short game practice – you choose!')
            .setFontFamily('Arial')
            .setWordWrapWidth(width - 80)
            .setColor('#000000')
            .setFontSize(12);

        const instructionImage = scene.add.image(40, text1.getBottomCenter().y + 20, 'instructions')
            .setOrigin(0, 0);

        const shotButton = scene.add.circle(width - 130, text1.getBottomCenter().y + 100, 30, 0x7199aa, 1)
            .setStrokeStyle(2, 0x000000, 1);

        const text2 = scene.add.text(40, instructionImage.getBottomCenter().y + 10, 'CONTROLS')
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(12);

        const text3 = scene.add.text(width - 170, instructionImage.getBottomCenter().y + 10, 'SHOT BUTTON')
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(12);

        const text4 = scene.add.text(40, text2.getBottomCenter().y + 20, 'Each shot is made up of four elements: aim, club face, swing path and power.')
            .setFontFamily('Arial')
            .setWordWrapWidth(width - 80)
            .setColor('#000000')
            .setFontSize(12);

        const text5 = scene.add.text(40, text4.getBottomCenter().y + 20, 'Tapping anywhere on the hole, or range will set the aim, then hit the blue button to set the other parameters.')
            .setFontFamily('Arial')
            .setWordWrapWidth(width - 80)
            .setColor('#000000')
            .setFontSize(12);

        const text6 = scene.add.text(40, text5.getBottomCenter().y + 20, 'Club face refers to the angle at which the club strikes the ball – this determines the starting direction of your shot.')
            .setFontFamily('Arial')
            .setWordWrapWidth(width - 80)
            .setColor('#000000')
            .setFontSize(12);

        const text7 = scene.add.text(40, text6.getBottomCenter().y + 20, 'The angle of the club face in relation to the swing path, determines the curve of your shot.  For example, if the swing path is to the right of the club face, the ball will curve to the left, and vice versa.')
            .setFontFamily('Arial')
            .setWordWrapWidth(width - 80)
            .setColor('#000000')
            .setFontSize(12);

        const text8 = scene.add.text(40, text7.getBottomCenter().y + 20, 'To hit a straight shot, the club face and swing path arrows should be in line with each other.')
            .setFontFamily('Arial')
            .setWordWrapWidth(width - 80)
            .setColor('#000000')
            .setFontSize(12);

        this.container = scene.add.container(0, 0, [background, close, howToPlay, text1, text2, text4, text5, text6, text7, text8, instructionImage, shotButton, text3])
            .setDepth(900);

        this.emitter.emit('ui-item-added', this.container);
    }
}