
import {Options, Vue} from 'vue-class-component';
import Range from '@/components/Range.vue'

@Options({
  components: {
    Range
  },
})
export default class RangeRoute extends Vue {
}
