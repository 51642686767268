import Phaser from 'phaser'
import ControlsGameObject from "../../objects/ControlsGameObject";
import EventDispatcher from "../../objects/EventDispatcher";
import ShotCalculator, {ShotParameters} from "@/game/objects/ShotCalculator";
import Vector2 = Phaser.Math.Vector2;
import Polygon = Phaser.GameObjects.Polygon;
import GameStatistics from "@/game/objects/GameStatistics";
import {GameStatus, Lie} from "@/game/objects/GameStatus";
import {Club} from "@/game/objects/Clubs";
import Color = Phaser.Display.Color;
import Circle = Phaser.Geom.Circle;
import Help from "@/game/objects/Help";

export default class HoleScene extends Phaser.Scene {

    private shotButton: any;
    private config: any;
    private style: any;
    private emitter: any;
    private readonly shots: Set<ShotParameters>;
    private readonly invalidShots: Set<ShotParameters>;
    private bounds = {width: 750, height: 1500};
    private sceneCamera: any;
    private uiCamera: any;
    private sceneObjects: Set<any> = new Set<any>();
    private uiObjects: Set<any> = new Set<any>();
    private currentShot: any;
    private scoreText: any;
    private distanceRemainingText: any;
    private distanceText: any;
    private lieIndicator: any;
    private lieText: any;
    private lieIndicatorText: any;
    private clubDistanceText: any;
    private statistics: GameStatistics;
    private readonly status: GameStatus;
    private share = {x: 0, y: 0, width: 0, height: 0};
    private clubText: any;
    private help: Help = new Help();
    private storageKeyName = "allmygolf-state";
    private timer: any;

    constructor() {
        super('hole-scene')
        this.shots = new Set<ShotParameters>();
        this.invalidShots = new Set<ShotParameters>();
        this.statistics = new GameStatistics();
        this.status = new GameStatus();
    }

    init(config: any) {
        this.config = config.config;
        this.style = config.style;
    }

    preload() {
        this.emitter = EventDispatcher.getInstance();
    }

    openExternalLink() {
        window.location.href = '/';
    }

    create() {
        // if (window.localStorage.getItem(this.storageKeyName) !== null) {
        //     const statistics = JSON.parse(window.localStorage.getItem(this.storageKeyName) as string);
        //     const localGameStatistics = Object.assign(new GameStatistics(), statistics)

        //     if (localGameStatistics.IsToday()) {
        //         this.statistics = localGameStatistics;
        //     }
        // }

        this.statistics = new GameStatistics();

        const {width, height} = this.sys.game.scale.gameSize;

        this.sceneCamera = this.cameras.main;
        this.sceneCamera.setBounds(0, 0, this.bounds.width, this.bounds.height);
        this.uiCamera = this.cameras.add(this.sceneCamera.x, this.sceneCamera.y, this.sceneCamera.width, this.sceneCamera.height);

        this.uiObjects.add(this.add.rectangle(0, 0, width, 40, 0x335566).setOrigin(0, 0));
        this.uiObjects.add(this.add.rectangle(10, 50, 90, 40, 0x335566).setOrigin(0, 0));
        this.uiObjects.add(this.add.rectangle(10, 90, 90, 40, 0x999999).setOrigin(0, 0));
        this.uiObjects.add(this.add.rectangle(100, 50, 45, 40, 0x999999).setOrigin(0, 0));

        this.uiObjects.add(this.add.text(10, 10, 'ALLMYGOLF')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(16)
            .setOrigin(0, 0)
            .setInteractive({useHandCursor: true})
            .on('pointerdown', this.openExternalLink, this));

        this.uiObjects.add(this.add.circle(width - 60, 5, 15)
            .setDepth(21)
            .setStrokeStyle(1, 0xffffff, 1)
            .setOrigin(0, 0)
            .setInteractive({useHandCursor: true})
            .on('pointerdown', this.showHelp, this));

        this.uiObjects.add(this.add.text(width - 51, 8, '?')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(22)
            .setOrigin(0, 0)
            .setDepth(20));

        this.uiObjects.add(this.add.text(115, 10, '|  ' + this.config.number)
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(16)
            .setOrigin(0, 0));

        this.uiObjects.add(this.add.text(20, 55, 'PAR ' + this.config.par)
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(14)
            .setOrigin(0, 0));

        this.distanceText = this.add.text(20, 70, '')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(14);

        this.uiObjects.add(this.distanceText);

        this.scoreText = this.add.text(123, 70, '')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(24)
            .setOrigin(0.5, 0.5);

        this.uiObjects.add(this.scoreText);

        this.uiObjects.add(this.add.text(20, 95, 'To hole')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(14)
            .setOrigin(0, 0));

        this.distanceRemainingText = this.add.text(20, 110, '')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(14)
            .setOrigin(0, 0);

        this.uiObjects.add(this.distanceRemainingText);

        this.uiObjects.add(this.add.rectangle(15, height - 60, 60, 20, 0x000000, 1)
            .setOrigin(0, 0)
            .setStrokeStyle(2, 0x000000, 1)
            .setDepth(100));

        this.uiObjects.add(this.add.rectangle(15, height - 120, 60, 20, 0x000000, 1)
            .setOrigin(0, 0)
            .setStrokeStyle(2, 0x000000, 1)
            .setDepth(100));

        this.lieIndicator = this.add.rectangle(15, height - 100, 60, 40, 0xffffff, 1)
            .setOrigin(0, 0)
            .setStrokeStyle(2, 0x000000, 1)
            .setDepth(100);

        this.lieText = this.add.text(38, height - 118, 'LIE')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(14)
            .setDepth(101)
            .setOrigin(0, 0);

        this.lieIndicatorText = this.add.text(46, height - 57, 'TEE')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(11)
            .setDepth(101)
            .setOrigin(0.5, 0);

        this.uiObjects.add(this.lieIndicator);
        this.uiObjects.add(this.lieText);
        this.uiObjects.add(this.lieIndicatorText);

        this.uiObjects.add(this.add.rectangle(80, height - 120, 50, 80, 0x000000, 1)
            .setOrigin(0, 0)
            .setStrokeStyle(2, 0x000000, 1)
            .setDepth(100));

        this.uiObjects.add(this.add.text(86, height - 118, 'CLUB')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(14)
            .setDepth(101)
            .setOrigin(0, 0));

        this.clubText = this.add.text(105, height - 93, 'CLUB')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(24)
            .setDepth(101)
            .setOrigin(0.5, 0);

        this.uiObjects.add(this.clubText);

        this.clubDistanceText = this.add.text(105, height - 57, '')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(11)
            .setDepth(101)
            .setOrigin(0.5, 0);

        this.uiObjects.add(this.clubDistanceText);

        this.uiObjects.add(this.add.rectangle(135, height - 120, 30, 30, 0x000000, 1)
            .setOrigin(0, 0)
            .setStrokeStyle(2, 0x000000, 1)
            .setDepth(100)
            .setInteractive({useHandCursor: true})
            .on('pointerdown', () => {
                this.status.Club = this.status.Clubs.GetNextClub(this.status.Club);
                this.clubText.setText(this.status.Club.Name);
                this.clubDistanceText.setText(this.status.Club.Max + ' yds');
                this.currentShot.destroy();
                this.currentShot = new ControlsGameObject(this, this.currentShot.x, this.currentShot.y, 0, this.status.Club, this.status.Lie);
                this.sceneObjects.add(this.currentShot);
            }));

        this.uiObjects.add(this.add.text(143, height - 120, '+')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(26)
            .setDepth(101)
            .setOrigin(0, 0));

        this.uiObjects.add(this.add.rectangle(135, height - 70, 30, 30, 0x000000, 1)
            .setOrigin(0, 0)
            .setStrokeStyle(2, 0x000000, 1)
            .setDepth(100)
            .setInteractive({useHandCursor: true})
            .on('pointerdown', () => {
                this.status.Club = this.status.Clubs.GetPreviousClub(this.status.Club);
                this.clubText.setText(this.status.Club.Name);
                this.clubDistanceText.setText(this.status.Club.Max + ' yds');
                this.currentShot.destroy();
                this.currentShot = new ControlsGameObject(this, this.currentShot.x, this.currentShot.y, 0, this.status.Club, this.status.Lie);
                this.sceneObjects.add(this.currentShot);
            }));

        this.uiObjects.add(this.add.text(145, height - 72, '-')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(26)
            .setDepth(101)
            .setOrigin(0, 0));

        this.renderTee();
        this.renderItem(this.config.rough, this.style.rough);
        this.renderItem(this.config.fairway, this.style.fairway);
        this.renderItem(this.config.green, this.style.green);
        this.renderItem(this.config.bunker, this.style.bunker);
        this.renderItem(this.config.ground, this.style.ground);
        this.renderItem(this.config.stream, this.style.stream);
        this.renderItem(this.config.pond, this.style.pond);
        this.renderItem(this.config.shadow, this.style.shadow);
        this.renderItem(this.config.ob, this.style.ob);
        this.renderItem(this.config.road, this.style.road);
        this.renderItem(this.config.tee, this.style.tee);
        this.renderTrees(this.config.tree, this.style.tree);

        this.sceneObjects.add(this.add.circle(
            this.config.green.hole.x,
            this.config.green.hole.y,
            4,
            0x000000)
            .setOrigin(0, 0)
            .setDepth(30));

        this.shotButton = this.add.circle(width - 100, height - 100, 30, 0x7199aa, 1)
            .setOrigin(0, 0)
            .setStrokeStyle(2, 0x000000, 1)
            .setDepth(100)
            .setInteractive({useHandCursor: true})
            .on('pointerdown', this.shotButtonClicked);

        const background = this.add.rectangle(0, 0, this.bounds.width, this.bounds.height,
            this.style.background.fill.colour, this.style.background.fill.alpha)
            .setOrigin(0, 0)
            .setDepth(2);

        this.uiObjects.add(this.shotButton);
        this.sceneObjects.add(background);

        this.input.on('pointermove', (pointer: any) => {

            if (!pointer.isDown) {
                return;
            }

            this.sceneCamera.scrollX -= (pointer.x - pointer.prevPosition.x) / this.sceneCamera.zoom;
            this.sceneCamera.scrollY -= (pointer.y - pointer.prevPosition.y) / this.sceneCamera.zoom;
        });

        this.emitter.on('shot-complete', this.handleShot.bind(this));
        this.emitter.on('item-added', this.handleItemAddedToScene.bind(this));
        this.emitter.on('ui-item-added', this.handleItemAddedToUi.bind(this));

        // if (this.statistics.IsToday()) {
        //     this.completeGame();
        // }
    }

    showHelp() {
        this.help.show(this);
    }

    completeGame() {

        const {width, height} = this.sys.game.scale.gameSize;

        const background = this.add.rectangle(
            width / 2,
            height / 2,
            width - 30,
            220,
            0xffffff, 1)
            .setDepth(99)
            .setStrokeStyle(0x000000, 2, 1)
            .setOrigin(0.5, 0.5);

        const messageText = this.add.text(background.x, (background.y - background.height / 2) + 30, "Hole " + this.config.number + " - Par " + this.config.par + " - " + this.statistics.TotalHoleDistance + " yds")
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(24)
            .setDepth(100)
            .setOrigin(0.5, 0.5);

        const scoreLabel = this.add.text(40, messageText.y + 30, "Score:")
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setDepth(100);

        const scoreValue = this.add.text(width - 40, scoreLabel.y, this.statistics.Score.toString())
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setDepth(100)
            .setOrigin(1, 0);

        const girText = this.add.text(40, scoreLabel.y + 20, "Green in regulation:")
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setDepth(100);

        const girValue = this.add.text(width - 40, girText.y, this.statistics.GreenInRegulation ? 'Yes' : "No")
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setDepth(100)
            .setOrigin(1, 0);

        const firText = this.add.text(40, girText.y + 20, "Fairway in regulation:")
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setDepth(100);

        const firValue = this.add.text(width - 40, firText.y, this.statistics.FairwayInRegulation ? 'Yes' : "No")
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setDepth(100)
            .setOrigin(1, 0);

        const driveDistanceText = this.add.text(40, firText.y + 20, "Drive distance:")
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setDepth(100);

        const driveDistanceValue = this.add.text(width - 40, driveDistanceText.y, this.statistics.DriveDistance.toString())
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setDepth(100)
            .setOrigin(1, 0);

        const longestDistanceText = this.add.text(40, driveDistanceText.y + 20, "Longest distance:")
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setDepth(100);

        const longestDistanceValue = this.add.text(width - 40, longestDistanceText.y, this.statistics.LongestDistance.toString())
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setDepth(100)
            .setOrigin(1, 0);

        const puttsText = this.add.text(40, longestDistanceText.y + 20, "Number of putts:")
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setDepth(100);

        const puttsValue = this.add.text(width - 40, puttsText.y, this.statistics.Putts.toString())
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(16)
            .setDepth(100)
            .setOrigin(1, 0);

        const linkText = this.add.text(width / 2, puttsText.y + 30, "https://allmy.golf")
            .setFontFamily('Arial')
            .setColor('#000000')
            .setFontSize(18)
            .setDepth(100)
            .setOrigin(0.5, 0);

        this.share = {
            x: background.getTopLeft().x,
            y: background.getTopLeft().y,
            width: background.width,
            height: background.height
        };

        const nextGameBackground = this.add.rectangle(
            width / 2,
            background.getBottomCenter().y + 40,
            width - 30,
            70,
            0xffffff, 1)
            .setDepth(99)
            .setStrokeStyle(0x000000, 2, 1)
            .setOrigin(0.5, 0.5);

        // const nextGameHeadingText = this.add.text(nextGameBackground.getTopLeft().x + 20, nextGameBackground.getTopLeft().y + 10, 'Next game')
        //     .setFontFamily('Arial')
        //     .setColor('#000000')
        //     .setFontSize(16)
        //     .setDepth(100);

        // const nextGameText = this.add.text(nextGameHeadingText.x, nextGameHeadingText.y + 25, this.statistics.GetNextGameTime())
        //     .setFontFamily('Arial')
        //     .setColor('#000000')
        //     .setFontSize(24)
        //     .setDepth(100);

        const shareButton = this.add.rectangle(nextGameBackground.getRightCenter().x - 170, nextGameBackground.getTopRight().y + 15, 150, 40, 0x7199aa, 1)
            .setOrigin(0, 0)
            .setStrokeStyle(1, 0x000000, 1)
            .setDepth(102)
            .setInteractive()
            .setOrigin(0, 0)
            .on('pointerdown', this.takeShotAndShare, this);

        const shareText = this.add.text(shareButton.getCenter().x, shareButton.getCenter().y, 'PLAY AGAIN')
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(16)
            .setDepth(103)
            .setOrigin(0.5, 0.5);

        //this.uiObjects.add([linkText, nextGameBackground, nextGameText, nextGameHeadingText, shareText, shareButton, background, messageText, scoreLabel, scoreValue, girText, girValue, firText, firValue, driveDistanceText, driveDistanceValue, longestDistanceText, longestDistanceValue, puttsText, puttsValue]);
        this.uiObjects.add([linkText, nextGameBackground, shareText, shareButton, background, messageText, scoreLabel, scoreValue, girText, girValue, firText, firValue, driveDistanceText, driveDistanceValue, longestDistanceText, longestDistanceValue, puttsText, puttsValue]);

        this.shotButton.disableInteractive();
        this.currentShot.destroy();
        this.input.removeAllListeners();

        if (!this.statistics.IsToday()) {
            this.statistics.LastPlayed = Date.now();
            window.localStorage.setItem(this.storageKeyName, JSON.stringify(this.statistics))
        }

        //this.timer = setInterval(this.doTimer, 1000, nextGameText, this.statistics);
    }

    // doTimer(gameText: any, statistics: GameStatistics) {
    //     gameText.setText(statistics.GetNextGameTime());
    // }


    handleItemAddedToScene(item: any) {
        this.sceneObjects.add(item);
    }

    handleItemAddedToUi(item: any) {
        this.uiObjects.add(item);
    }

    takeShotAndShare() {
        window.location.reload();
        //window.location.href = '/course';
        // const holeNumber = this.config.number;
        // this.game.renderer.snapshotArea(this.share.x, this.share.y, this.share.width, this.share.height, async function (image: HTMLImageElement | Color) {
        //     const base64url = (image as HTMLImageElement).attributes[0].value;
        //     const blob = await (await fetch(base64url)).blob();

        //     const filesArray = [
        //         new File(
        //             [blob],
        //             'allmygolf-results-' + holeNumber + '.png',
        //             {
        //                 type: "image/png",
        //                 lastModified: new Date().getTime()
        //             }
        //         )
        //     ];

        //     const shareData = {
        //         title: 'AllMyGolf Results for Hole ' + holeNumber.toString(),
        //         files: filesArray,
        //     };

        //     await navigator.share(shareData);
        // });
    }

    handleShot(shotParameters: ShotParameters) {

        shotParameters.Points = Array.from(this.calculatePoints(shotParameters));
        const isValidShot = this.isValidShot(shotParameters);

        if (isValidShot) {
            this.shots.add(shotParameters);
            this.statistics.Score++;
        } else {
            this.invalidShots.add(shotParameters);
            this.statistics.Score++;
            this.currentShot.destroy();
            this.currentShot = new ControlsGameObject(this, shotParameters.start.x, shotParameters.start.y, 0, this.status.Club as Club, this.status.Lie);
            this.sceneObjects.add(this.currentShot);
        }

        shotParameters.RenderedQueue = Array.from(shotParameters.Points);
    }

    shotButtonClicked() {
        EventDispatcher.getInstance().emit('shot-button-clicked', {});
    }

    calculateDistanceToHole(point: Vector2): integer {
        const distance = Phaser.Math.Distance.BetweenPoints(
            new Vector2(point.x, point.y),
            new Vector2(this.config.green.hole.x, this.config.green.hole.y));

        return this.status.ModifyYardage(distance);
    }

    update() {

        this.invalidShots.forEach((e) => {
            if (e.RenderedQueue.length > 0) {
                const radius = 2;

                const ball = this.add.circle(
                    e.RenderedQueue[0].x,
                    e.RenderedQueue[0].y,
                    radius,
                    0xff0000)
                    .setDepth(44);

                this.sceneObjects.add(ball);

                e.RenderedQueue.splice(0, 1);
            }
        });

        this.shots.forEach((e) => {
            if (e.RenderedQueue.length > 0) {

                const radius = 2;

                const ball = this.add.circle(
                    e.RenderedQueue[0].x,
                    e.RenderedQueue[0].y,
                    radius,
                    0xffffff)
                    .setDepth(44);

                this.sceneObjects.add(ball);

                e.RenderedQueue.splice(0, 1);

                if (e.RenderedQueue.length == 0) {

                    const lastPoint = e.Points[e.Points.length - 1];
                    this.status.Position = lastPoint;

                    this.distanceRemainingText.text = this.calculateDistanceToHole(lastPoint).toString() + ' yards';

                    const greenPolygon = new Polygon(this,
                        0,
                        0,
                        this.config.green.points[0]).setOrigin(0, 0);

                    this.setCurrentLie(lastPoint);

                    const isOnGreen = Phaser.Geom.Polygon.ContainsPoint(greenPolygon.geom, new Phaser.Geom.Point(lastPoint.x, lastPoint.y));
                    if (isOnGreen) {

                        let putts: number;

                        const distanceToHole = this.calculateDistanceToHole(lastPoint);

                        if (distanceToHole <= 1) {
                            putts = 0;
                        } else {
                            const chance = Phaser.Math.Between(0, 999);

                            if (chance < 20) {
                                if (distanceToHole <= 10) {
                                    putts = 2;
                                } else if (distanceToHole > 10 && distanceToHole <= 20) {
                                    putts = 3;
                                } else {
                                    putts = 4;
                                }
                            } else {
                                if (distanceToHole <= 4) {
                                    if (chance > 500) {
                                        putts = 1;
                                    } else {
                                        putts = Phaser.Math.Between(1, 2);
                                    }
                                } else if (distanceToHole > 4 && distanceToHole <= 20) {
                                    putts = Phaser.Math.Between(1, 2);
                                } else {
                                    putts = Phaser.Math.Between(1, 3);
                                }
                            }
                        }

                        this.statistics.Score += putts;
                        this.updateStatistics(isOnGreen, putts, lastPoint);

                        this.completeGame();

                    } else {

                        const distanceToHole = this.calculateDistanceToHole(new Vector2(lastPoint.x, lastPoint.y));
                        this.status.Club = this.status.Clubs.GetByYardage(distanceToHole, this.status.Lie);

                        this.clubDistanceText.setText(this.status.Club.Max + ' yds');

                        this.currentShot = new ControlsGameObject(this, lastPoint.x, lastPoint.y, 0, this.status.Club as Club, this.status.Lie);
                        this.sceneObjects.add(this.currentShot);

                        this.updateStatistics(isOnGreen, 0, lastPoint);
                    }
                }
            }
        });

        if (this.status.Lie == Lie.Background) {
            this.lieIndicatorText.text = Lie[Lie.Rough].toUpperCase();
        } else {
            this.lieIndicatorText.text = Lie[this.status.Lie].toUpperCase();
        }

        switch (this.status.Lie) {
            case Lie.Tee:
                this.lieIndicator.setFillStyle(this.style.tee.fill.colour, 1);
                break;
            case Lie.Green:
                this.lieIndicator.setFillStyle(this.style.green.fill.colour, 1);
                break;
            case Lie.Trees:
                this.lieIndicator.setFillStyle(this.style.tree.fill.colour, 1);
                break;
            case Lie.Fairway:
                this.lieIndicator.setFillStyle(this.style.fairway.fill.colour, 1);
                break;
            case Lie.Bunker:
                this.lieIndicator.setFillStyle(this.style.bunker.fill.colour, 1);
                break;
            case Lie.Rough:
                this.lieIndicator.setFillStyle(this.style.rough.fill.colour, 1);
                break;
            case Lie.Ground:
                this.lieIndicator.setFillStyle(this.style.ground.fill.colour, 1);
                break;
            case Lie.Background:
                this.lieIndicator.setFillStyle(this.style.background.fill.colour, 1)
                break;
        }

        this.clubText.text = this.status.Club?.Name;
        this.scoreText.text = this.statistics.Score;
        this.sceneCamera.ignore(Array.from(this.uiObjects.values()));
        this.uiCamera.ignore(Array.from(this.sceneObjects.values()));
    }

    updateStatistics(
        isOnGreen: boolean,
        numberOfPutts: integer,
        position: Vector2) {

        switch (this.config.par) {
            case 3:
                if (this.shots.size == 1 && isOnGreen) {
                    this.statistics.GreenInRegulation = true;
                }
                break;
            case 4:
                if (this.shots.size <= 2 && isOnGreen) {
                    this.statistics.GreenInRegulation = true;
                }

                if (this.shots.size == 1 && this.isIn(this.config.fairway, position)) {
                    this.statistics.FairwayInRegulation = true;
                }

                break;
            case 5:
                if (this.shots.size <= 3 && isOnGreen) {
                    this.statistics.GreenInRegulation = true;
                }
                if (this.shots.size == 1 && this.isIn(this.config.fairway, position)) {
                    this.statistics.FairwayInRegulation = true;
                }
                break;
        }

        const shots = [...this.shots];
        const shot = shots[shots.length - 1];

        const distance = this.status.ModifyYardage(Phaser.Math.Distance.BetweenPoints(shot.start, shot.end as Vector2));

        if (distance > this.statistics.LongestDistance) {
            this.statistics.LongestDistance = distance;
        }

        if (shots.length == 1) {
            this.statistics.DriveDistance = distance;
        }

        if (isOnGreen) {
            this.statistics.Putts = numberOfPutts;
        }
    }

    showMessage(message: string, interval: integer = 3000) {

        const messageText = this.add.text(this.sys.game.scale.gameSize.width / 2, 180, message)
            .setFontFamily('Arial')
            .setColor('#ffffff')
            .setFontSize(20)
            .setDepth(100)
            .setOrigin(0.5, 1);

        const messageBox = this.add.rectangle(
            this.sys.game.scale.gameSize.width / 2, messageText.y - 10,
            messageText.width + 30,
            messageText.height + 30,
            0x000000, 1)
            .setDepth(99)
            .setOrigin(0.5, 0.5);

        this.uiObjects.add(messageText);
        this.uiObjects.add(messageBox);

        this.time.delayedCall(interval, () => {
            messageText.destroy();
            messageBox.destroy();
        }, [], this);
    }

    isValidShot(shotParameters: ShotParameters): boolean {

        let valid = true;

        shotParameters.Points.forEach((p) => {
            if (valid) {
                if (p.x < 0) {
                    valid = false;
                    this.showMessage("Out of bounds");
                    return valid;
                }
                if (p.y < 0) {
                    valid = false;
                    this.showMessage("Out of bounds");
                    return valid;
                }
                if (p.x > this.bounds.width) {
                    valid = false;
                    this.showMessage("Out of bounds");
                    return valid;
                }
                if (p.y > this.bounds.height) {
                    valid = false;
                    this.showMessage("Out of bounds");
                    return valid;
                }
            }
        });

        const endPoint = shotParameters.Points[shotParameters.Points.length - 1];
        const endpointGeom = new Phaser.Geom.Point(endPoint.x, endPoint.y)

        for (let i = 0; i < this.config.stream.points.length; i++) {
            const point = this.config.stream.points[i];
            const poly = new Polygon(this, 0, 0, point).setOrigin(0, 0);
            if (Phaser.Geom.Polygon.ContainsPoint(poly.geom, endpointGeom)) {
                valid = false;
                this.showMessage("Water!");
                return valid;
            }
        }

        for (let i = 0; i < this.config.ob.points.length; i++) {
            const point = this.config.ob.points[i];
            const poly = new Polygon(this, 0, 0, point).setOrigin(0, 0);
            if (Phaser.Geom.Polygon.ContainsPoint(poly.geom, endpointGeom)) {
                valid = false;
                this.showMessage("Out of bounds!");
                return valid;
            }
        }

        const message = "Water!";

        for (let i = 0; i < this.config.pond.points.length; i++) {
            const point = this.config.pond.points[i];
            const poly = new Polygon(this, 0, 0, point).setOrigin(0, 0);
            if (Phaser.Geom.Polygon.ContainsPoint(poly.geom, endpointGeom)) {
                valid = false;
            }
        }

        this.config.fairway.points.forEach((op: any) => {
            const poly = new Polygon(this, 0, 0, op).setOrigin(0, 0);
            if (Phaser.Geom.Polygon.ContainsPoint(poly.geom, endpointGeom)) {
                valid = true;
            }
        })

        this.config.ground.points.forEach((op: any) => {
            const poly = new Polygon(this, 0, 0, op).setOrigin(0, 0);
            if (Phaser.Geom.Polygon.ContainsPoint(poly.geom, endpointGeom)) {
                valid = true;
                console.log('ground');
            }
        })

        this.config.rough.points.forEach((op: any) => {
            const poly = new Polygon(this, 0, 0, op).setOrigin(0, 0);
            if (Phaser.Geom.Polygon.ContainsPoint(poly.geom, endpointGeom)) {
                valid = true;
            }
        })

        this.config.green.points.forEach((op: any) => {
            const poly = new Polygon(this, 0, 0, op).setOrigin(0, 0);
            if (Phaser.Geom.Polygon.ContainsPoint(poly.geom, endpointGeom)) {
                valid = true;
            }
        })

        if (!valid) {
            this.showMessage(message);
        }

        return valid;
    }

    calculatePoints(e: ShotParameters): Vector2[] {

        const parameters = ShotCalculator.Calculate(e);

        const curve = new Phaser.Curves.QuadraticBezier(
            parameters.start,
            <Vector2>parameters.middle,
            <Vector2>parameters.end);

        const points = curve.getPoints(parameters.Power * 7) as Vector2[];

        const treePoints = new Set<Vector2>();
        let closest = 1000;
        let point: any;

        const finishedPoints = new Set<Vector2>();

        points.forEach(m => {
            this.config.tree.points.forEach((t: any) => {
                const radius = 42 / t[2];
                const tree = new Circle(t[0], t[1], radius);
                if (tree.contains(m.x, m.y)) {
                    treePoints.add(m);
                }
            });
        });

        treePoints.forEach(m => {
            const dist = Phaser.Math.Distance.BetweenPoints(m, parameters.start);
            if (dist < closest) {
                closest = dist;
                point = m;
            }
        });

        if (point) {

            const shouldHitTree = Phaser.Math.RoundTo(Phaser.Math.Between(1, 3), 0) === 1;

            if (shouldHitTree) {
                const index = points.findIndex(a => a == point);

                for (let i = 0; i < index; i++) {
                    finishedPoints.add(points[i]);
                }

                const randomDirection = Phaser.Math.Between(2, 350);
                const randomPower = Phaser.Math.Between(1, 2);

                const shotParams = new ShotParameters(
                    point, 0, randomDirection, randomDirection + 5, randomPower, this.status.Club as Club, Lie.Trees);

                const shotCalculationResults = ShotCalculator.Calculate(shotParams);

                const curve1 = new Phaser.Curves.QuadraticBezier(
                    shotCalculationResults.start,
                    <Vector2>shotCalculationResults.middle,
                    <Vector2>shotCalculationResults.end);

                const points1 = curve1.getPoints(10);

                for (let i = 0; i < points1.length; i++) {
                    finishedPoints.add(points1[i]);
                }
            } else {
                points.forEach(m => {
                    finishedPoints.add(m);
                });
            }
        } else {
            points.forEach(m => {
                finishedPoints.add(m);
            });
        }

        return Array.from(finishedPoints);
    }

    renderTrees(item: any, style: any) {
        item.points.forEach((p: any) => {

            const texture = Phaser.Math.RoundTo(Phaser.Math.Between(1, 2), 0);

            this.sceneObjects.add(this.add.sprite(p[0], p[1], 'tree' + texture)
                .setDepth(style.depth)
                .setAngle(Phaser.Math.Between(0, 360))
                .setScale(p[2]));
        });
    }

    renderItem(item: any, style: any) {

        item.points.forEach((p: any) => {

            const curve = new Phaser.Curves.Spline(p.concat(p));

            const graphics = this.add.graphics()
                .lineStyle(style.stroke.width, style.stroke.colour, style.stroke.alpha)
                .setDepth(style.depth)
                .fillStyle(style.fill.colour, style.fill.alpha)

            curve.draw(graphics, 200);
            graphics.fillPath();

            this.sceneObjects.add(curve);
            this.sceneObjects.add(graphics);
        });
    }

    setCurrentLie(point: Vector2) {
        if (this.shots.size >= 1) {
            if (this.isIn(this.config.green, point)) {
                this.status.Lie = Lie.Green;
                return;
            }
            if (this.isIn(this.config.bunker, point)) {
                this.status.Lie = Lie.Bunker;
                return;
            }
            if (this.isIn(this.config.fairway, point)) {
                this.status.Lie = Lie.Fairway;
                return;
            }
            if (this.isIn(this.config.ground, point)) {
                this.status.Lie = Lie.Ground;
                return;
            }
            if (this.isIn(this.config.rough, point)) {
                this.status.Lie = Lie.Rough;
                return;
            }

            this.status.Lie = Lie.Background;
        }
    }

    isIn(item: any, point: Vector2): boolean {
        let isIn = false;
        item.points.forEach((p: any) => {
            const poly = new Polygon(this, 0, 0, p).setOrigin(0, 0);
            if (Phaser.Geom.Polygon.ContainsPoint(poly.geom, new Phaser.Geom.Point(point.x, point.y))) {
                isIn = true;
            }
        });
        return isIn;
    }

    renderTee() {

        // const offset = this.style.tee.offset;
        // let teeCoordinates = {x: this.bounds.width / 2, y: this.bounds.height - offset};
        // let angle = 0;
        // //let origin = {x: 0.5, y: 0.5};
        //
        // switch (this.config.tee.position) {
        //     case 'left': {
        //         teeCoordinates = {x: 100, y: this.bounds.height - offset};
        //         //origin = {x: 0.44, y: 0.55};
        //         angle = 45;
        //         break;
        //     }
        //     case 'right': {
        //         teeCoordinates = {x: this.bounds.width - 100, y: this.bounds.height - offset};
        //         //origin = {x: 0.56, y: 0.56};
        //         angle = 315;
        //         break;
        //     }
        // }

        // const teeFairway = this.add.rectangle(teeCoordinates.x, teeCoordinates.y - 10, 70, 100, this.style.fairway.fill.colour, this.style.fairway.fill.alpha)
        //     .setStrokeStyle(this.style.fairway.stroke.width, this.style.fairway.stroke.colour, this.style.fairway.stroke.alpha)
        //     .setOrigin(origin.x, origin.y)
        //     .setDepth(this.style.tree.depth - 1)
        //     .setAngle(angle);
        //
        // const teeBox = this.add.rectangle(teeCoordinates.x, teeCoordinates.y, 40, 40, this.style.tee.fill.colour, this.style.tee.fill.alpha)
        //     .setStrokeStyle(this.style.tee.stroke.width, this.style.tee.stroke.colour, this.style.tee.stroke.alpha)
        //     .setOrigin(0.5, 0.5)
        //     .setDepth(this.style.tree.depth - 1)
        //     .setAngle(angle);

        // this.sceneObjects.add(teeBox);
        // this.sceneObjects.add(teeFairway);

        const startX = this.config.tee.position.x;
        const startY = this.config.tee.position.y;

        this.sceneCamera.centerOn(startX, startY);

        this.status.TeePosition = new Vector2(startX, startY);

        const distanceToHole = this.calculateDistanceToHole(new Vector2(startX, startY));

        this.status.Club = this.status.Clubs.GetByYardage(distanceToHole, Lie.Tee);
        this.clubDistanceText.setText(this.status.Club.Max + ' yds');
        this.status.Lie = Lie.Tee;

        this.currentShot = new ControlsGameObject(this, startX, startY, this.config.tee.position.angle, this.status.Club, this.status.Lie);
        this.sceneObjects.add(this.currentShot);

        this.distanceRemainingText.text = distanceToHole.toString() + ' yards';
        this.distanceText.text = this.distanceRemainingText.text;

        this.statistics.TotalHoleDistance = distanceToHole;
    }
}
