import Phaser from 'phaser'

import BaseScene from "@/game/scenes/Game/BaseScene";
import HoleScene from "@/game/scenes/Game/HoleScene";

function launch(containerId: any) {
    return new Phaser.Game({
        backgroundColor: '#667f29',
        width: 750,
        height: 1500,
        type: Phaser.CANVAS,
        parent: containerId,
        scale: {
            mode: Phaser.Scale.RESIZE,
            autoCenter: Phaser.Scale.RESIZE
        },
        fps: {
            target: 60,
            forceSetTimeOut: true
        },
        scene: [BaseScene, HoleScene]
    })
}

export default launch
export {launch}
