import Phaser from 'phaser'

import BaseScene from "@/game/scenes/Range/BaseScene";
import RangeScene from "@/game/scenes/Range/RangeScene";

function launch(containerId: any) {
    
    if(window.outerHeight < 500){
        
        return new Phaser.Game({
            backgroundColor: '#667f29',
            width: 600,
            height: 720,
            type: Phaser.CANVAS,
            parent: containerId,
            scale: {
                mode: Phaser.Scale.FIT,
                autoCenter: Phaser.Scale.CENTER_HORIZONTALLY
            },
            fps: {
                target: 60,
                forceSetTimeOut: true
            },
            scene: [BaseScene, RangeScene]
        });
    }
    
    return new Phaser.Game({
        backgroundColor: '#667f29',
        width: 500,
        height: 1000,
        type: Phaser.CANVAS,
        parent: containerId,
        scale: {
            mode: Phaser.Scale.RESIZE,
            autoCenter: Phaser.Scale.RESIZE
        },
        scene: [BaseScene, RangeScene]
    })
}

export default launch
export {launch}
