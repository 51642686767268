import Vector2 = Phaser.Math.Vector2;
import {Club, Clubs} from "@/game/objects/Clubs";
import Phaser from "phaser";

enum Lie {
    Tee,
    Fairway,
    Green,
    Rough,
    Bunker,
    Trees,
    Ground,
    Background
}

class GameStatus {

    public Position: Vector2;
    public TeePosition: Vector2;
    public Lie: Lie;
    public Clubs: Clubs;
    public Club: Club;
    public DistanceModifier = 2.2;

    constructor() {
        this.Position = new Vector2(0, 0);
        this.TeePosition = new Vector2(0, 0);
        this.Lie = Lie.Tee;
        this.Clubs = new Clubs();
        this.Club = this.Clubs.GetDiver();
    }

    public ModifyYardage(distance: integer): integer {
        return Phaser.Math.RoundTo((distance / this.DistanceModifier), 0);
    }
}

export {Lie, GameStatus}