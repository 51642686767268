import Phaser from "phaser";
import EventDispatcher from "../objects/EventDispatcher"
import {ShotParameters} from "../objects/ShotCalculator";
import Guid from "../objects/Guid";
import ControlsGameObjectState from "../objects/ControlsGameObjectState";
import {Club} from "@/game/objects/Clubs";
import {Lie} from "@/game/objects/GameStatus";
import Vector2 = Phaser.Math.Vector2;
import Sprite = Phaser.GameObjects.Sprite;

export default class ControlsGameObject extends Phaser.GameObjects.GameObject {

    private button: any;
    private targetSprite: any;
    private faceSprite: any;
    private pathSprite: any;
    private faceDirection = 1;
    private pathDirection = 1;
    private rotateFace = false;
    private rotatePath = false;
    private readonly x;
    private readonly y;
    private emitter: any;
    private readonly faceSpeed = 2.7;
    private readonly pathSpeed = 2.9;
    private readonly maxAngle = 50;
    private readonly powerSpeed = 18;
    private readonly id: any;
    private readonly defaultAngle: any;
    private controlstate: ControlsGameObjectState = ControlsGameObjectState.Start;
    private readonly club: Club;
    private readonly lie: Lie;

    constructor(scene: any, x: integer, y: integer, defaultAngle = 0, club: Club, lie: Lie) {
        super(scene, 'controls');
        this.x = x;
        this.y = y;
        this.defaultAngle = defaultAngle;
        this.setActive(true);

        this.club = club;
        this.lie = lie;

        this.emitter = EventDispatcher.getInstance();
        scene.add.existing(this);
        this.scene.events.on('update', () => {
            this.update()
        });
        this.draw();

        this.emitter.on('shot-button-clicked', this.handleShotButtonClicked.bind(this));

        this.id = Guid.newGuid();
    }

    public destroy() {
        if (this.button) {
            this.button.destroy();
        }
        if (this.targetSprite) {
            this.targetSprite.destroy();
        }
        if (this.pathSprite) {
            this.pathSprite.destroy();
        }
        if (this.faceSprite) {
            this.faceSprite.destroy();
        }

        this.controlstate = ControlsGameObjectState.Complete;
    }

    draw() {
        this.scene.input.on('pointerdown', (p: any, o: any) => this.selectTarget(this.x, this.y, p, o));

        this.button = this.scene.add.sprite(this.x, this.y, "controls")
            .setName(this.id)
            .setDepth(81);

        this.emitter.emit('item-added', this.button);

        this.targetSprite = this.scene.add.sprite(this.x, this.y, 'target')
            .setDepth(80)
            .setOrigin(0.5, 1)
            .setAngle(this.defaultAngle);

        this.faceDirection = Math.round(Phaser.Math.Between(0, 1));
        this.pathDirection = Math.round(Phaser.Math.Between(0, 1));

        this.emitter.emit('item-added', this.targetSprite);
    }

    handleShotButtonClicked(message: any) {
        if (this.controlstate === ControlsGameObjectState.Start) {
            this.handleTarget();
            this.controlstate = ControlsGameObjectState.Face;
            return;
        }
        if (this.controlstate === ControlsGameObjectState.Face) {
            this.handleFace();
            this.controlstate = ControlsGameObjectState.Path;
            return;
        }
        if (this.controlstate === ControlsGameObjectState.Path) {
            this.handlePath();
            this.controlstate = ControlsGameObjectState.Power;
            return;
        }
        if (this.controlstate === ControlsGameObjectState.Power) {
            this.finishShot();
            this.controlstate = ControlsGameObjectState.Complete;
            return;
        }
    }

    selectTarget(
        x: integer,
        y: integer,
        target: Phaser.Input.Pointer,
        objectsClicked: Phaser.GameObjects.GameObject[]) {

        if (objectsClicked.length !== 0) {
            if (objectsClicked[0].name == this.id) {
                this.handleTarget();
            }
            return;
        }

        if (this.controlstate > ControlsGameObjectState.Start) {
            return;
        }

        const degrees = Phaser.Math.RadToDeg(Phaser.Math.Angle.Between(this.x, this.y, target.worldX, target.worldY)) + 90;

        if (!this.targetSprite) {
            this.targetSprite = this.scene.add.sprite(this.x, this.y, 'target')
                .setDepth(80)
                .setOrigin(0.5, 1);
        }

        this.targetSprite.setAngle(degrees);
    }

    handleTarget() {
        if (!this.targetSprite) {
            return;
        }

        if (!this.faceSprite) {
            this.faceSprite = this.scene.add.sprite(this.x, this.y, 'face')
                .setDepth(80)
                .setOrigin(0.50, 1);
        }

        this.setSpriteStartingAngle(this.faceSprite);
        this.rotateFace = true;

        this.emitter.emit('item-added', this.faceSprite);
    }

    handleFace() {
        this.rotateFace = false;

        this.pathSprite = this.scene.add.sprite(this.x, this.y, 'path').setDepth(80);
        this.pathSprite.setOrigin(0.5, 1);
        this.setSpriteStartingAngle(this.pathSprite);
        this.rotatePath = true;

        this.emitter.emit('item-added', this.pathSprite);
    }

    normalizeAngle(angle: number): number {
        let a = angle;

        if (angle < 0) {
            a += 360;
        }

        if (angle > 360) {
            a -= 360;
        }

        a = Phaser.Math.RoundTo(a, 0);

        return a;
    }

    handlePath() {
        this.rotatePath = false;

        this.scene.anims.create({
            key: "power",
            frameRate: this.powerSpeed,
            frames: this.scene.anims.generateFrameNumbers("controls", {start: 1, end: 10}),
            repeat: -1,
            yoyo: true,
        });

        this.button = this.scene.add.sprite(this.x, this.y, "controls")
            .setDepth(83);

        this.button.play("power");

        this.emitter.emit('item-added', this.button);
    }

    finishShot() {
        this.button.stop();

        this.emitter.emit("shot-complete",
            new ShotParameters(
                new Vector2(this.x, this.y),
                this.targetSprite.angle,
                this.faceSprite.angle,
                this.pathSprite.angle,
                this.button.frame.name,
                this.club,
                this.lie));

        this.scene.tweens.add({
            targets: [this.targetSprite, this.faceSprite, this.pathSprite, this.button],
            alpha: {from: 1, to: 0.0},
            ease: 'Sine.InOut',
            duration: 1500,
            yoyo: false
        });
    }

    update() {
        if (this.rotateFace) {

            const targetAngle = this.normalizeAngle(this.targetSprite.angle);

            let nextAngle = 0;
            if (this.faceDirection == 1) {
                nextAngle = this.faceSprite.angle + this.faceSpeed;
            }

            if (this.faceDirection == 0) {
                nextAngle = this.faceSprite.angle - this.faceSpeed;
            }

            const angleDiff = (nextAngle - targetAngle + 180 + 360) % 360 - 180;

            if (angleDiff <= this.maxAngle && angleDiff >= -this.maxAngle) {
                const a = 'asd';
            } else {
                this.faceDirection = (this.faceDirection + 1) % 2
            }

            if (this.faceDirection == 1) {
                this.faceSprite.angle += this.faceSpeed;
            }

            if (this.faceDirection == 0) {
                this.faceSprite.angle -= this.faceSpeed;
            }
        }

        if (this.rotatePath) {

            const targetAngle = this.normalizeAngle(this.targetSprite.angle);

            let nextAngle = 0;
            if (this.pathDirection == 1) {
                nextAngle = this.pathSprite.angle + this.faceSpeed;
            }

            if (this.pathDirection == 0) {
                nextAngle = this.pathSprite.angle - this.pathSpeed;
            }

            const angleDiff = (nextAngle - targetAngle + 180 + 360) % 360 - 180;

            if (angleDiff <= this.maxAngle && angleDiff >= -this.maxAngle) {
                const a = 'asd';
            } else {
                this.pathDirection = (this.pathDirection + 1) % 2
            }

            if (this.pathDirection == 1) {
                this.pathSprite.angle += this.pathSpeed;
            }

            if (this.pathDirection == 0) {
                this.pathSprite.angle -= this.pathSpeed;
            }
        }
    }

    setSpriteStartingAngle(item: Sprite) {
        item.angle = Phaser.Math.Between(
            this.targetSprite.angle + this.maxAngle,
            this.targetSprite.angle - this.maxAngle);
    }
}