import {Lie} from "@/game/objects/GameStatus";

export class Clubs {

    private bag: Club[] = [
        new Club("60°", 18, 82, 2),
        new Club("54°", 21, 95, 2),
        new Club("50°", 24, 109, 3),
        new Club("PW", 27, 123, 3),
        new Club("9I", 30, 135, 5),
        new Club("8I", 33, 150, 5),
        new Club("7I", 36, 165, 5),
        new Club("6I", 39, 175, 8),
        new Club("5I", 41, 185, 8),
        new Club("4I", 44, 195, 8),
        new Club("5W", 45, 204, 8),
        new Club("3W", 48, 218, 8),
        new Club("DR", 57, 260, 10)
    ];
    
    public GetDiver(): Club{
        return this.bag[this.bag.length - 1];
    }

    public GetByYardage(yardage: integer, lie: Lie): Club {

        for (let i = 0; i < this.bag.length; i++) {
            if (this.bag[i].Max >= yardage) {
                return this.bag[i];
            }
        }
        
        if (lie == Lie.Tee) {
            return this.bag[this.bag.length - 1];
        }

        return this.bag[this.bag.length - 2];
    }

    public GetNextClub(current: Club): Club {
        for (let i = 0; i < this.bag.length; i++) {
            if (this.bag[i].Name == current.Name) {
                if (i == this.bag.length - 1) {
                    return this.bag[i];
                }

                return this.bag[i + 1];
            }
        }
        
        return this.GetDiver();
    }

    public GetPreviousClub(current: Club): Club {
        for (let i = 0; i < this.bag.length; i++) {
            if (this.bag[i].Name == current.Name) {
                if (i == 0) {
                    return this.bag[i];
                }

                return this.bag[i - 1];
            }
        }
        return this.GetDiver();
    }
}

export class Club {
    public Name: string;
    public Power: integer;
    public Max: integer;
    public CurveModifier: integer;

    constructor(name: string, power: integer, max: integer, curveModifier: integer) {
        this.Name = name;
        this.Power = power;
        this.Max = max;
        this.CurveModifier = curveModifier;
    }
}

